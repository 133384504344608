/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type SuggestionService from 'embercom/services/suggestion-service';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import type Model from '@ember-data/model';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type IntlService from 'ember-intl/services/intl';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type { ReviewableContent } from '../content-review';
import { EntityType } from 'embercom/models/data/entity-types';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';

interface Args {
  showSidePanel: boolean;
  toggleSidePanel: () => void;
  activeContent: LocalizedKnowledgeContent & Model;
  approveVersion: () => void;
  rejectVersion: () => void;
  disableButtons: boolean;
  saveAsDraft: () => void;
  onClose?: () => void;
  reviewRequests: ContentReviewRequest[];
}

export default class Header extends Component<Args> {
  closeSidePanelOnResize = true;

  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intl: IntlService;
  @service declare suggestionService: SuggestionService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  get isContentSnippet() {
    return this.args.activeContent.entityType === EntityType.ContentSnippet;
  }

  get isArticleContent() {
    return this.args.activeContent.entityType === EntityType.ArticleContent;
  }

  get approveButtonLabel() {
    return this.isArticleContent
      ? this.intl.t('knowledge-hub.content-editor.drawer-editor.review-mode-header.publish-changes')
      : this.intl.t('knowledge-hub.content-editor.drawer-editor.review-mode-header.approve');
  }

  get title() {
    let content = this.args.activeContent;
    let objectName = objectNames[content.entityType];
    let reviewType = (content as ReviewableContent).contentReviewRequest.get('reviewType');
    let contentTitle = this.args.activeContent.get('title');

    if (reviewType === ReviewType.CREATE_CONTENT) {
      return `${this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.add-${objectName}`)}: ${contentTitle}`;
    } else if (reviewType === ReviewType.EDIT_CONTENT) {
      return `${this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.edit-${objectName}`)}: ${contentTitle}`;
    } else if (reviewType === ReviewType.DELETE_CONTENT) {
      return `${this.intl.t(`knowledge-hub.content-editor.drawer-editor.titles.delete-${objectName}`)}: ${contentTitle}`;
    }

    return this.intl.t(
      `knowledge-hub.content-editor.drawer-editor.titles.fallback-title-${objectName}`,
    );
  }

  get isAnyPending() {
    return this.args.reviewRequests.any((request) => request.get('status') === Status.Pending);
  }

  get isAllApproved() {
    return this.args.reviewRequests.every((request) => request.get('status') === Status.Approved);
  }

  get isAllRejected() {
    return this.args.reviewRequests.every((request) => request.get('status') === Status.Rejected);
  }

  get showRejectButton() {
    return this.isContentSnippet && this.isAnyPending;
  }

  get showSaveAsDraftButton() {
    return this.isArticleContent;
  }

  get isSaveAsDraftDisabled() {
    if (this.isArticleContent) {
      if (
        this.args.reviewRequests.any((request) => request.get('status') === Status.Approved) ||
        this.suggestionService.hasDirtyContent
      ) {
        return false;
      }
    }

    return true;
  }

  get showApproveButton() {
    if (this.isContentSnippet) {
      return this.isAnyPending;
    } else {
      return this.isAllApproved || (this.isAllRejected && this.suggestionService.hasDirtyContent);
    }
  }

  @action closeSidePanel(header: HTMLElement) {
    let headerHasScroll = header.offsetWidth < header.scrollWidth;
    let closeSidePanel = headerHasScroll && this.closeSidePanelOnResize && this.args.showSidePanel;
    this.closeSidePanelOnResize = !headerHasScroll && this.args.showSidePanel;
    if (closeSidePanel) {
      // schedule the side panel to close after it finishes opening, to avoid a race condition
      schedule('afterRender', () => {
        this.args.toggleSidePanel();
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview::Header': typeof Header;
    'knowledge-hub/content-editor/content-review/header': typeof Header;
  }
}
